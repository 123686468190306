<template>
  <div class="design-examination">
    <el-form inline>
      <el-form-item label="类型" prop="type">
        <el-select v-model="searchParams.type" clearable>
          <el-option v-for="type of types" :key="type.value" :label="type.label"
                     :value="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标段" prop="tenderGuid">
        <el-select v-model="searchParams.tenderGuid" clearable>
          <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                     :value="tender.tenderGuid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工期" prop="periodSerialNo">
        <el-input v-model="searchParams.periodSerialNo" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick">查询</el-button>
        <el-button v-if="couldAdd" @click="handleAddClick">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border header-cell-class-name="bg-info text-light">
      <el-table-column align="center" :formatter="typeFormatter" prop="type" label="类型"></el-table-column>
      <el-table-column align="center" prop="tender.tenderName" label="标段"></el-table-column>
      <el-table-column align="center" prop="periodSerialNo" label="工期"></el-table-column>
      <el-table-column align="center" prop="currentPeriodAmount" label="申报金额"></el-table-column>
      <el-table-column align="center" prop="currentPeriodApprovalAmount" label="审批金额"></el-table-column>
      <el-table-column align="center" prop="approvalUser.userName" label="审批人"></el-table-column>
      <el-table-column align="center" :formatter="statusFormatter" prop="status" label="审批状态"></el-table-column>
      <el-table-column align="center" prop="declarationDate" label="创建时间"></el-table-column>
      <el-table-column align="center" prop="approvalDate" label="审批时间"></el-table-column>
      <el-table-column align="center" width="300px" label="操作">
        <template v-if="!!scope.row.designExaminationGuid" slot-scope="scope">
          <el-button v-if="couldFinalApproval && scope.row.status === 0" type="primary" size="mini"
                     @click="handleApprovalClick(scope.row)">批复
          </el-button>
          <el-button v-if="couldFinalApproval && scope.row.status === 1" type="primary" size="mini"
                     @click="handleUndoApprovalClick(scope.row)">取消批复
          </el-button>
          <el-button type="primary" size="mini" @click="handleCheckReportClick(scope.row)">查看报表</el-button>
          <el-button v-if="couldAdd" type="danger" size="mini" @click="handleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="添加" :visible.sync="formDialogVisible" destroy-on-close>
      <el-form ref="formRef" :model="designExamination" :rules="rules" label-width="170px">
        <el-form-item label="类型" prop="type">
          <el-select v-model="designExamination.type" clearable>
            <el-option v-for="type of types" :key="type.value" :label="type.label"
                       :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标段" prop="tenderGuid">
          <el-select v-model="designExamination.tenderGuid" clearable>
            <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                       :value="tender.tenderGuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工期" prop="periodSerialNo">
          <el-input-number :min="1" :step="1" step-strictly
                           v-model="designExamination.periodSerialNo"></el-input-number>
        </el-form-item>
        <el-form-item label="金额" prop="currentPeriodAmount">
          <el-input-number :min="0" :step="0.01" step-strictly
                           v-model="designExamination.currentPeriodAmount"></el-input-number>
        </el-form-item>
        <el-form-item label="日期" prop="declarationDate">
          <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                          v-model="designExamination.declarationDate"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOkClick">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="审批" :visible.sync="approvalDialogVisible" destroy-on-close>
      <el-form ref="approvalForm" :model="designExamination" :rules="approvalRules" label-width="170px">
        <el-form-item label="金额" prop="currentPeriodApprovalAmount">
          <el-input-number :min="0" :step="0.01" step-strictly
                           v-model="designExamination.currentPeriodApprovalAmount"></el-input-number>
        </el-form-item>
        <el-form-item label="日期" prop="approvalDate">
          <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                          v-model="designExamination.approvalDate"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleApprovalCancelClick">取 消</el-button>
        <el-button type="primary" @click="handleApprovalOkClick">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'DesignExamination',
  data () {
    return {
      types: [
        { label: '初步设计', value: 0 },
        { label: '勘查设计', value: 1 }
      ],
      rules: {
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        tenderGuid: [
          { required: true, message: '请选择标段', trigger: 'blur' }
        ],
        periodSerialNo: [
          { required: true, message: '请输入工期', trigger: 'blur' }
        ],
        currentPeriodAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        declarationDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      },
      approvalRules: {
        currentPeriodApprovalAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        approvalDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      },
      approvalDialogVisible: false,
      formDialogVisible: false,
      searchParams: {
        tenderGuid: '',
        periodSerialNo: '',
        type: ''
      },
      tenderList: [],
      list: [],
      designExamination: {
        designExaminationGuid: 'dummy',
        declarationDate: '',
        approvalDate: '',
        status: 0,
        currentPeriodApprovalAmount: 0,
        periodSerialNo: 1,
        currentPeriodAmount: 0,
        type: 0,
        tenderGuid: ''
      }
    }
  },
  computed: {
    couldAdd () {
      return auth.getUserInfo().identityGuid === 'ba9399f0-2ead-11ef-83e3-edd756a2a4ac'
    },
    couldFinalApproval () {
      return auth.getUserInfo().identityGuid.toUpperCase() === 'DD511FC7-AE1B-4BAB-8957-F3B2B7D542EC'
    }
  },
  methods: {
    typeFormatter (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return '初步设计'
        case 1:
          return '勘查设计'
        default:
          return ''
      }
    },
    handleCheckReportClick (row) {
      this.$router.push({
        path: `/designReport?designExaminationGuid=${row.designExaminationGuid}`
      })
    },
    handleUndoApprovalClick (row) {
      this.$confirm('确认取消批复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const response = await axios.post(`${config.restHost}/designExaminations/undoApproval/${row.designExaminationGuid}`, {}, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        if (response.data.code === 0) {
          this.$message.error(response.data.msg)
        } else {
          this.$message.success('取消批复成功')
        }
        await this.getData()
      }).catch(() => {
        this.$message.info('已取消操作')
      })
    },
    handleApprovalClick (row) {
      this.designExamination = row
      this.approvalDialogVisible = true
    },
    async handleApprovalOkClick () {
      await this.$refs.approvalForm.validate(async valid => {
        if (!valid) {
          this.$message.warning('请检查输入')
          return
        }
        const response = await axios.post(`${config.restHost}/designExaminations/approval/${this.designExamination.designExaminationGuid}`, {}, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          },
          params: {
            approvalAmount: this.designExamination.currentPeriodApprovalAmount,
            approvalDate: this.designExamination.approvalDate
          }
        })

        if (response.data.code === 0) {
          this.$message.error(response.data.msg)
          return
        }

        this.$message.success('审批成功')
        this.approvalDialogVisible = false
        await this.getData()
      })
    },
    handleApprovalCancelClick () {
      this.approvalDialogVisible = false
    },
    async handleSearchClick () {
      await this.getData()
    },
    async handleOkClick () {
      await this.$refs.formRef.validate(async valid => {
        if (!valid) {
          this.$message.warning('请检查输入')
          return
        }
        const response = await axios.post(`${config.restHost}/designExaminations`, this.designExamination, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })

        if (response.data.code === 0) {
          this.$message.error(response.data.msg)
          return
        }

        this.$message.success('添加成功')
        this.formDialogVisible = false
        await this.getData()
      })
    },
    handleAddClick () {
      this.formDialogVisible = true
    },
    handleCancel () {
      this.designExamination = {
        designExaminationGuid: 'dummy',
        declarationDate: '',
        status: 0,
        currentPeriodApprovalAmount: 0,
        periodSerialNo: 1,
        currentPeriodAmount: 0,
        type: 0,
        tenderGuid: '',
        approvalDate: ''
      }
      this.formDialogVisible = false
    },
    handleDeleteClick (row) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const response = await axios.delete(`${config.restHost}/designExaminations/${row.designExaminationGuid}`, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        if (response.data.code === 0) {
          this.$message.error(response.data.msg)
        } else {
          this.$message.success('删除成功')
        }
        await this.getData()
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    statusFormatter (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return '未审批'
        case 1:
          return '已审批'
        default:
          return ''
      }
    },
    async getData () {
      const response = await axios.get(`${config.restHost}/designExaminations`, {
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        },
        params: this.searchParams
      })
      console.log(response)
      this.list = response.data.list
    },
    async prepare () {
      const tenderListResponse = await axios.get(`${config.restHost}/api/tenders`, {
        params: {
          projectGuid: auth.getUserInfo().projectGuid
        },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.tenderList = tenderListResponse.data
    }
  },
  async mounted () {
    await this.prepare()
    await this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>
